<script setup>
import {ref, onMounted, watch} from "vue";
import Popover from "primevue/popover";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

 const props = defineProps({
   range: {
     type: String,
     required: true
   },
   startDate: {
     type: Number,
   },
   endDate: {
     type: Number,
   }
 })

const emits = defineEmits(['updateDates'])

const rangeSelected = ref('week')

const op = ref();

const date = ref(new Date());
// const startDate = ref(new Date())
// const endDate = ref(new Date(new Date().setDate(startDate.value.getDate() + 7)))
const startDate = ref(props.startDate)
const endDate = ref(props.endDate)
const flow = ref(['calendar'])

const week = ref([startDate.value, endDate.value])
const month = ref({
  month: new Date().getMonth(),
  year: new Date().getFullYear()
});
const year = ref(new Date().getFullYear())


// For demo purposes assign range from the current date
// onMounted(() => {
//
//   console.log(date.value[0])
// })

const togglePopover = (event) => {
  op.value.toggle(event);
}

// Prop watch
watch(() => props.range, (newRange) => {
  rangeSelected.value = newRange
})

// Prop watch
watch(() => [props.startDate, props.endDate], ([newStartDate, newEndDate]) => {
  startDate.value = newStartDate
  endDate.value = newEndDate
})

// Date selected watch
watch(date, (dateSelected) => {
  startDate.value = dateSelected
  endDate.value = dateSelected
})

watch(week, ([newStart, newEnd]) => {
  startDate.value = newStart
  endDate.value = newEnd
})

watch(month, (newMonth) => {
  startDate.value = new Date(newMonth.year, newMonth.month, 1);
  endDate.value = new Date(newMonth.year, newMonth.month + 1, 0);
})

watch(year, (newYear) => {
  startDate.value = new Date(newYear, 0, 1);
  endDate.value = new Date(newYear, 12, 0);
})

// Change of start and end watch
watch([startDate, endDate], ([newStartDate, newEndDate]) => {
  // Emit new dates to dashboard page to update requests

  startDate.value = newStartDate
  endDate.value = newEndDate
  emits("updateDates", startDate.value, endDate.value)
})
</script>

<template>
<button @click="togglePopover">
  <div class="flex flex-row underline underline-offset-4 font-semibold">
    <span>{{ startDate.toLocaleDateString() }}</span><span>  -  </span><span> {{ endDate.toLocaleDateString() }}</span>
    <img :src="require('../../assets/images/calendar.svg')" alt="" class="w-4 h-auto ml-2">
  </div>
</button>
  <Popover ref="op">
    <VueDatePicker v-if="rangeSelected === 'day'" v-model="date" :max-date="new Date()" :flow="flow"/>
    <VueDatePicker v-else-if="rangeSelected === 'week'" v-model="week" week-picker/>
    <VueDatePicker v-else-if="rangeSelected === 'year'" v-model="year" year-picker/>
    <VueDatePicker v-else-if="rangeSelected === 'month'" v-model="month" month-picker/>
  </Popover>
</template>

<style scoped>

</style>