import { createWebHistory, createRouter } from "vue-router";
import { routes } from "./routes";

const router = createRouter({
    // baseUrl: process.env.BASE_URL,
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // console.log("TO", to, "FROM", from, "NEXT", next);
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = [
        // "/",
        "/login",
        "/signup",
        "/reset-password",
        "/forgot-password",
        "/email-sended",
        "/disable-alert",
    ];
    const path = `/${to.path.split("/")[1]}`;
    const authRequired = !publicPages.includes(path);
    const hasToken = localStorage.getItem("jwt");
    const loggedIn = Boolean(hasToken);
    //
    // // console.log("authRequired && !loggedIn", authRequired, loggedIn);
    //
    if (authRequired && !loggedIn) {
        return next({ name: "Login" });
    }
    //
    if (!authRequired && loggedIn) {
        return next("/dashboard");
    }

    // store.commit('setPrevRoute', from.path);
    return next();
    // return next({ name: "Login" });
});

export default router;