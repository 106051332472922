<script setup>
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { useToast } from 'primevue/usetoast';
import {HTTP} from "@/Plugins/axios";
import { useRouter } from 'vue-router'
import { ref } from "vue";


const API_URL = process.env.VUE_APP_API_URL;
const toast = useToast();
const router = useRouter()

const form = ref({
  email: '',
});

const forgotPassword = ({email}) => {
  HTTP({
    url: `${API_URL}forgot-password`,
    method: 'POST',
    data: {
      email
    }
  })
    .then((response) => {

      router.push('/login');
    })
    .catch((err) => {
      form.value.error = err.message;
      toast.add({ severity: 'error', summary: 'There was an error', detail: '', life: 3000 });
    })

};
</script>

<template>
    <GuestLayout>
      <Toast />
      <Head title="Forgot password" />

        <form @submit.prevent="forgotPassword(form)">
            <div>
                <InputLabel for="email" value="Email" />

                <TextInput
                    id="email"
                    type="email"
                    class="mt-1 block w-full"
                    v-model="form.email"
                    required
                    autofocus
                    autocomplete="username"
                />

            </div>
          <div class="block my-6">
            <label class="flex items-center">
              <span class="ml-2 text-sm text-gray-600">Remembered your password? </span>
              <span class="ml-2 text-blue-600 underline"> <RouterLink to="/login">Login here</RouterLink></span>
            </label>
          </div>

            <div class="flex items-center justify-end mt-4">
                <PrimaryButton class="ml-4">
                    Send email
                </PrimaryButton>
            </div>
        </form>
    </GuestLayout>
</template>
