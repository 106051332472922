<script setup>
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue";
import AddButton from "@/Components/Default/AddButton.vue";
import Tab from "@/Components/Default/Tab.vue";
import Credit from "@/Components/Credit/Credit.vue";


import {onBeforeMount, ref} from "vue";
import {HTTP} from "@/Plugins/axios";
import { useRouter } from "vue-router";

const API_URL = process.env.VUE_APP_API_URL;
const router = useRouter();
const available_total = ref(0);
const used_total = ref(0);

const credits = ref();
// Todo: implementar listado con los creditos de la petición
const getCredits = () => {
  HTTP({
    url: `${API_URL}credits`,
    method: 'GET',
  })
      .then((response) => {
        credits.value = response.data.credits;
        available_total.value = response.data.total;
        used_total.value = response.data.total_used;
      })
      .catch((err) => {
        console.log(err)
      })
}

onBeforeMount(() => {
  getCredits()
})
</script>

<template>
  <AuthenticatedLayout>
    <div class="flex flex-col bg-finexpal-gray-300 w-full">
      <div class="flex justify-center gap-x-6 mt-4">
        <tab id="expenses" class="bg-finexpal-gray-600">
          Credits
        </tab>
      </div>
      <div class="flex justify-center align-center p-4">
        <div class="flex flex-col justify-center align-center ml-52">
          <div class="flex justify-center align-center underline underline-offset-4 font-semibold">
            <span>$ {{ used_total }}</span>
          </div>
          <div class="flex justify-center align-center">
            <span>/  $ {{ available_total }}</span>
          </div>
        </div>
        <div class="w-[8rem] pl-4 ml-16 mr-4">
          <RouterLink to="/credits/create">
            <AddButton />
          </RouterLink>
        </div>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 grid-rows-auto w-full mb-4">
        <Credit
            v-for="item in credits"
            :id="item.id"
            :name="item.name"
            :quantity="item.quantity"
            :limit="item.limit"
            :payday="item.payday"
            :cutday="item.cutday"
            class="flex mx-auto mt-10" />
      </div>
    </div>
  </AuthenticatedLayout>
</template>