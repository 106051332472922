import LoginPage from "@/Pages/Auth/LoginPage.vue";
import SignUpPage from "@/Pages/Auth/SignUpPage.vue";
import ForgotPasswordPage from "@/Pages/Auth/ForgotPasswordPage.vue";
import ResetPasswordPage from "@/Pages/Auth/ResetPasswordPage.vue";
import DashboardPage from "@/Pages/DashboardPage.vue";
import AccountsPage from "@/Pages/Accounts/AccountsPage.vue";
import EditAccountPage from "@/Pages/Accounts/EditAccountPage.vue";

import CreditsPage from "@/Pages/Credits/CreditsPage.vue"
import CreateCreditPage from "@/Pages/Credits/CreateCreditPage.vue";
import EditCreditPage from "@/Pages/Credits/EditCreditPage.vue";

import ChartsPage from "@/Pages/ChartsPage.vue"
import CategoriesPage from "@/Pages/CategoriesPage.vue"

import RecurrentsPage from "@/Pages/RecurrentsPage.vue"
import CreateRecurrentExpense from "@/Pages/RecurrentExpenses/CreateRecurrentExpensePage.vue";
import CreateRecurrentIncome from "@/Pages/RecurrentIncomes/CreateRecurrentIncomePage.vue";
import EditRecurrentExpense from "@/Pages/RecurrentExpenses/EditRecurrentExpensePage.vue";
import EditRecurrentIncome from "@/Pages/RecurrentIncomes/EditRecurrentIncomePage.vue";

import ConfigurationPage from "@/Pages/ConfigurationPage.vue"
import CreateExpensePage from "@/Pages/Expenses/CreateExpensePage.vue"
import EditExpensePage from "@/Pages/Expenses/EditExpensePage.vue"
import EditIncomePage from "@/Pages/Incomes/EditIncomePage.vue"
import CreateIncomePage from "@/Pages/Incomes/CreateIncomePage.vue";
import CreateAccountPage from "@/Pages/Accounts/CreateAccountPage.vue";

export const routes = [
    {
        name: 'Login',
        path: '/login',
        component: LoginPage
    },
    {
        name: 'SignUp',
        path: '/signup',
        component: SignUpPage
    },
    {
        name: 'ForgotPassword',
        path: '/forgot-password',
        component: ForgotPasswordPage
    },
    {
        name: 'ResetPassword',
        path: '/reset-password/:token',
        component: ResetPasswordPage
    },
    {
        name: 'Dashboard',
        path: '/dashboard',
        component: DashboardPage
    },
    {
        path: '/',
        redirect: '/dashboard'
    },
    // Account Routes
    {
        name: 'Accounts',
        path: '/accounts',
        component: AccountsPage
    },
    {
        name: 'CreateAccount',
        path: '/accounts/create',
        component: CreateAccountPage
    },
    {
        name: 'EditAccount',
        path: '/accounts/:id',
        component: EditAccountPage
    },
    // Credit Routes
    {
        name: 'Credits',
        path: '/credits',
        component: CreditsPage,
    },
    {
        name: 'CreateCredit',
        path: '/credits/create',
        component: CreateCreditPage,
    },
    {
        name: 'EditCredit',
        path: '/credits/:id',
        component: EditCreditPage
    },
    // Chart Routes
    {
        name: 'Charts',
        path: '/charts',
        component: ChartsPage
    },
    // Categories Routes
    {
        name: 'Categories',
        path: '/categories',
        component: CategoriesPage
    },
    // Recurrent Expenses Routes
    {
        name: 'Recurrents',
        path: '/recurrents',
        component: RecurrentsPage
    },
    {
        name: 'CreateRecurrentExpense',
        path: '/recurrents/expenses/create',
        component: CreateRecurrentExpense
    },
    {
        name: 'EditRecurrentExpense',
        path: '/recurrents/expenses/:id',
        component: EditRecurrentExpense
    },
    {
        name: 'CreateRecurrentIncome',
        path: '/recurrents/incomes/create',
        component: CreateRecurrentIncome
    },
    {
        name: 'EditRecurrentIncome',
        path: '/recurrents/incomes/:id',
        component: EditRecurrentIncome
    },
    // Config Route
    {
        name: 'Configuration',
        path: '/configuration',
        component: ConfigurationPage
    },
    // Expense Routes
    {
        name: 'Expenses',
        path: '/expenses',
        // component: ExpensePage,
    },
    {
        name: 'CreateExpense',
        path: '/expenses/create',
        component: CreateExpensePage
    },
    {
        name: 'EditExpense',
        path: '/expenses/:id',
        component: EditExpensePage
    },
    // Income Routes
    {
        name: 'Incomes',
        path: '/incomes',
        // component: IncomePage,
    },
    {
        name: 'CreateIncome',
        path: '/incomes/create',
        component: CreateIncomePage
    },
    {
        name: 'EditIncome',
        path: '/incomes/:id',
        component: EditIncomePage
    },
];