<script setup>
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { useToast } from 'primevue/usetoast';
import {HTTP} from "@/Plugins/axios";
import { useRouter, useRoute } from 'vue-router'
import { ref } from "vue";
const route = useRoute();

const API_URL = process.env.VUE_APP_API_URL;
const toast = useToast();
const router = useRouter()

defineProps({});
const form = ref({
  email: route.query.email,
  password: '',
  password_confirmation: '',
  token: route.params.token,
});


const resetPassword = ({email,password, password_confirmation, token}) => {
  HTTP({
    url: `${API_URL}reset-password`,
    method: 'POST',
    data: {
      email,
      password,
      password_confirmation,
      token
    }
  })
    .then(() => {
      router.push('/login');
    })
    .catch((err) => {
      toast.add({ severity: 'error', summary: 'There was an error', detail: err.message, life: 3000 });
    })

};

const validateSubmit = (form) => {
  if (!form.password || !form.password_confirmation)
    return true;

  if (form.password !== form.password_confirmation)
    return true;

}
</script>

<template>
    <GuestLayout>
      <Toast />
      <Head title="Recover password" >
        <title>
          Finexpal
        </title>
      </Head>
        <form @submit.prevent="resetPassword(form)">
<!--          <div>-->
<!--            <InputLabel for="email" value="Email" />-->

<!--            <TextInput-->
<!--                id="email"-->
<!--                type="email"-->
<!--                class="mt-1 block w-full"-->
<!--                v-model="form.email"-->
<!--                required-->
<!--                autofocus-->
<!--                autocomplete="username"-->
<!--            />-->
<!--          </div>-->

          <div class="mt-4">
            <InputLabel for="password" value="Password" />

            <TextInput
                id="password"
                type="password"
                class="mt-1 block w-full"
                v-model="form.password"
                required
                autocomplete="current-password"
            />

            <!--                <InputError v-if="form.error.length !== 0" class="mt-2" :message="form.error" />-->
          </div>

            <div class="mt-4">
                <InputLabel for="passwordConfirmation" value="Confirm Password" />

                <TextInput
                    id="passwordConfirmation"
                    type="password"
                    class="mt-1 block w-full"
                    v-model="form.password_confirmation"
                    required
                    autocomplete="current-password"
                />

<!--                <InputError v-if="form.error.length !== 0" class="mt-2" :message="form.error" />-->
            </div>
            <div class="flex items-center justify-end mt-4">
                <PrimaryButton class="ml-4" :disabled="validateSubmit(form)">
                    Confirm password
                </PrimaryButton>
            </div>
        </form>
    </GuestLayout>
</template>
