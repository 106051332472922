<script setup>
import CreditEdit from "@/Components/Credit/CreditEdit.vue";

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  name: {
    type: String,
    required: true
  },
  quantity: {
    type: String,
    required: true
  },
  limit: {
    type: String,
    required: true
  },
  payday: {
    type: Number,
    required: true
  },
  cutday: {
    type: Number,
    required: true
  },
  colours: {
    type: Array,
    required: false
  }
});

const formatCurrency = (value) => {
  let val = (value/1).toFixed(2).replace(',','.')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
const createCreditBG = () => {
  return `linear-gradient(0deg, rgba(34,40,195,1) 0%, rgba(45,122,253, 0.77) 100%)`;
}

</script>

<template>
  <div :style="`background:${null ?? '#73C2BE'}`" class="flex flex-col justify-between h-[12rem] sm:h-60 w-4/5 sm:w-[25rem] rounded-lg px-4 py-2" >
    <div class="flex justify-between">
      <div class="flex flex-col text-start">
        <div class="font-semibold text-2xl">
          {{ props.name }}
        </div>
        <div>
          Fecha de corte: <b>{{ props.cutday }}</b>
        </div>
      </div>
      <RouterLink :to="`credits/${props.id}`">
        <CreditEdit />
      </RouterLink>
    </div>
    <div class="flex justify-between">
      <div class=" text-start">
        Fecha de pago: <b>{{ props.payday }}</b>
      </div>
      <div class="flex flex-col text-end">
        <div class="font-semibold text-xl">
          $ {{ formatCurrency(props.quantity) }}
        </div>
        <div class="">
          / $ {{ formatCurrency(props.limit) }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>